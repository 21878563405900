<template>
  <div
    class="menu-list-class"
    :style="'width: ' + (isCollapse ? 100 : 250) + 'px ;'"
  >
    <!-- :style="'width: '+ menuWidth +'px ;'" -->
    <!-- 11111 -->
    <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
      <el-radio-button :label="false">展开</el-radio-button>
      <el-radio-button :label="true">收起</el-radio-button>
    </el-radio-group> -->
    <div class="collapse-class">
      <div></div>
      <div>
        <div class="collapse-btn" @click="isCollapse = !isCollapse">
          <i
            :class="
              isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'
            "
          ></i>
        </div>
      </div>
    </div>
    <!-- :default-active="currentPath"  -->
    <el-menu
      :default-active="$store.getters.MenuType.SubmenuUrl"
      text-color="#fff"
      background-color="#001529"
      class="el-menu-vertical-demo"
      :collapse-transition="false"
      @select="select"
      :collapse="isCollapse"
    >
      <!-- @open="handleOpen"
      @close="handleClose" -->
      <div v-for="(item, index) in menuList" :key="index">
        <el-submenu
          :index="item.path === '/' ? item.id + '' : item.path"
          v-if="item.children && item.children.length"
        >
          <span slot="title">
            <!-- <i :class="item.icon"></i>{{item.name}} -->
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </span>
          <div v-for="(ite, inde) in item.children" :key="inde">
            <el-submenu
              :index="ite.path === '/' ? ite.id + '' : ite.path"
              v-if="ite.children && ite.children.length"
            >
              <span slot="title">{{ ite.name }}</span>
              <el-menu-item
                :index="ita.path === '/' ? ita.id + '' : ita.path"
                v-for="(ita, ind) in ite.children"
                :key="ind"
              >
                {{ ita.name }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item :index="ite.path" v-else>
              <i :class="ite.icon"></i>
              <span slot="title">{{ ite.name }}</span>
            </el-menu-item>
          </div>
        </el-submenu>
        <el-menu-item
          :index="item.path === '/' ? item.id + '' : item.path"
          v-else
        >
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
import './index.scss';
import router from '@/router';
import { getSessionStore } from '@/utils/storage';

export default {
  name: 'Index',
  data() {
    return {
      menuWidth: 100,
      isCollapse: false,
      menuList: [],
      currentPath: '/'
    };
  },
  watch: {
    // isCollapse(newV) {
    //   console.log(newV, 'newV');
    //   if (newV) this.menuWidth = 100; else this.menuWidth = 250;
    // },
  },
  created() {
    const SubmenuList = getSessionStore('menus', 'JSONStr');
    const navSelf = getSessionStore('navSelf', 'JSONStr');
    this.menuList = SubmenuList;
    this.currentPath = navSelf.SubmenuUrl;
  },
  methods: {
    select(e) {
      console.log('select', e);
      this.toPage(e);
    },
    toPage(url) {
      if (this.$route.path === url) {
        return true;
      }
      console.log('toPage', url);
      this.currentPath = url;
      // this.$store.dispatch('config/setSubmenuList', this.currentPath);
      router.push(url);
    },
    jump(item) {
      if (this.currentPath === item.path) {
        return true;
      }
      this.currentPath = item.path;
      router.push(item.path);
    }
  }
};
</script>

<style scoped>
.collapse-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  /* background-color: #fff; */
}
.collapse-btn {
  color: #fff;
  font-size: 20px;
  padding: 10px;
}
.menu-list-class {
  height: 100vh;
  overflow-y: auto;
  background-color: #001529;
}
/* .el-menu */
.el-menu {
  border: none;
  /* background-color: #001529; */
}
/* .el-submenu{
  color: #fff;
}
.el-menu-item{
  color: #303133;
} */
.is-active {
  background-color: #ebeef5;
  color: #409eff;
}
.activ-class {
  background-color: #ebeef5;
  color: #409eff;
}
</style>
