import { setSessionStore } from '@/utils/storage';

const state = {
  layoutType: 'layoutType3',
  menuList: [],
  MenuType: {
    asideWidth: '50px',
    rightMenuType: false,
    ParentMenuUrl: 2019, // 一级菜单目录
    SubmenuUrl: '/', // 子菜单目录
    SubmenuTitle: '' // 子菜单title
  },
  SubmenuList: [],
  tableConfig: {
    size: 'small', // medium / small / mini
    searchMenuSpan: 4,
    card: true, // 卡片
    border: true, // 边框
    filterBtn: false, // 筛选
    tip: false, // 多选表格上方显示的tag
    labelWidth: 120,
    cancelBtn: true,
    selection: false,
    columnBtn: false,
    index: true,
    searchSize: 'small'
  }
};

const mutations = {
  SET_ERROR_LAYOUTYPE: (state, layoutType) => {
    state.layoutType = layoutType;
  },
  SET_MENU_LIST: (state, menuList) => {
    const filterMenuList = (list) => {
      // debugger D目录M菜单 B按钮 L链接 I iframe
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (
          (!item.hidden && item.type === 'D') ||
          (!item.hidden && item.type === 'M')
        ) {
          if (item.children && item.children.length > 0) {
            filterMenuList(item.children);
          }
        } else if (
          item.type === 'B' ||
          (item.hidden && item.type === 'L') ||
          (item.hidden && item.type === 'I') ||
          (item.hidden && item.type === 'M')
        ) {
          list.splice(i, 1);
          i--;
        } else if (item.type === 'L' || item.type === 'I') {
          list[i].children = null;
        }
      }
      return list;
    };
    filterMenuList(menuList);
    state.menuList = menuList;
  },

  SET_NENU_TYPE: (state, data) => {
    state.MenuType = data;
    setSessionStore('navSelf', state.MenuType, 'JSONStr');
  },
  SET_SUBNENU_LIST: (state, data) => {
    state.SubmenuList = data;
    setSessionStore('SubmenuList', state.SubmenuList, 'JSONStr');
  },
  SET_TABLE_CONFIG: (state, data) => {
    state.tableConfig = {};
    state.tableConfig = {
      ...data,
      ...state.tableConfig
    };
    setSessionStore('TableConfig', data, 'JSONStr');
  }
};

const actions = {
  setLayoutType({ commit }, data) {
    commit('SET_ERROR_LAYOUTYPE', data);
    setSessionStore('layoutType', data);
  },
  setmenuList({ commit }, data) {
    commit('SET_MENU_LIST', data);
  },

  setSubmenuList({ commit }, data) {
    commit('SET_SUBNENU_LIST', data);
  },
  setMenuType({ commit }, data) {
    commit('SET_NENU_TYPE', data);
  },

  setUserInfo({ commit }, data) {
    commit('SET_USER_INFO', data);
  },
  setTableConfig({ commit }, data) {
    commit('SET_TABLE_CONFIG', data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
