export default {
  typeIndex: { type: Number, default: 0 },
  typeList: { type: Array, default: () => [] },
  typeContextMenuList: { type: Array, default: () => [] },
  list: { type: Array, default: () => [] },
  listIndex: { type: Number, default: 0 },
  listContextMenuList: { type: Array, default: () => [] },
  loading: { type: Boolean, default: false },
  typeCreate: { type: Boolean, default: false },
  typeEdit: { type: Boolean, default: false },
  create: { type: Boolean, default: false },
  edit: { type: Boolean, default: false },
  listCreateButtonName: { type: String, default: '添加数据' },
  listName: { type: String, default: '数据' }
};
