const getters = {
  // 菜单
  layoutType: (state) => state.config.layoutType,
  menuList: (state) => state.config.menuList,
  tableConfig: (state) => state.config.tableConfig,
  SubmenuList: (state) => state.config.SubmenuList,
  MenuType: (state) => state.config.MenuType,
  // 用户模块
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  // app模块
  size: (state) => state.app.size,
  theme: (state) => state.app.theme,
  sidebar: (state) => state.app.sidebar,
  fullScreen: (state) => state.app.fullScreen,
  // 权限模块
  menu: (state) => state.permission.menu,
  dataPerms: (state) => state.permission.dataPerms,
  // 错误日志模块
  errorLogs: (state) => state.errorLog.logs
};
export default getters;
