/**
 * 对sessionStorage操作
 */

/* eslint-disable no-underscore-dangle */
/* eslint-disable quotes */
/* eslint-disable no-else-return */
import { validatenull } from './validate';

export const setSessionStore = (key, value, type) => {
  if (!key) return;
  if (type === 'JSONStr') {
    value = JSON.stringify(value);
  }
  sessionStorage.setItem(key, value);
};

export const getSessionStore = (key, type) => {
  // 当sessionStorage中没有该key时，返回null
  if (!key) return null;
  let value = sessionStorage.getItem(key);
  // value为空时，返回null
  if (validatenull(value)) return null;
  if (type === 'JSONStr') {
    value = JSON.parse(value);
  }
  return value;
};

export const removeSessionStore = (key) => {
  if (!key) return;
  sessionStorage.removeItem(key);
};

/**
 * 对localStorage操作
 */
export const setLocalStore = (key, value, type) => {
  if (!key) return;
  if (type === 'JSONStr') {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
};
export const getLocalStore = (key, type) => {
  if (!localStorage.getItem(key)) {
    return;
  }
  if (type === 'JSONStr') {
    return JSON.parse(localStorage.getItem(key));
  } else {
    return localStorage.getItem(key);
  }
};
export const removeLocalStore = (key) => {
  if (!key) return;
  localStorage.removeItem(key);
};
