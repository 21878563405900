var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title"},[_vm._v("表格设置")]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"item-label",staticStyle:{"margin-right":"5px"}},[_vm._v("表格尺寸：")]),_c('avue-radio',{attrs:{"dic":[
        {
          label: 'small',
          value: 'small'
        },
        {
          label: 'medium',
          value: 'medium'
        },
        {
          label: 'mini',
          value: 'mini'
        }
      ]},model:{value:(_vm.form['size']),callback:function ($$v) {_vm.$set(_vm.form, 'size', $$v)},expression:"form['size']"}})],1),_c('div',{staticClass:"item"},[_c('span',{staticClass:"item-label",staticStyle:{"margin-right":"5px"}},[_vm._v("搜索尺寸：")]),_c('avue-radio',{attrs:{"dic":[
        {
          label: 'small',
          value: 'small'
        },
        {
          label: 'medium',
          value: 'medium'
        },
        {
          label: 'mini',
          value: 'mini'
        }
      ]},model:{value:(_vm.form['searchSize']),callback:function ($$v) {_vm.$set(_vm.form, 'searchSize', $$v)},expression:"form['searchSize']"}})],1),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('span',{staticClass:"item-label",staticStyle:{"margin-right":"5px"}},[_vm._v(_vm._s(item.label)+"：")]),_c('avue-switch',{model:{value:(_vm.form[item.prop]),callback:function ($$v) {_vm.$set(_vm.form, item.prop, $$v)},expression:"form[item.prop]"}})],1)}),_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sbm}},[_vm._v("设置")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }