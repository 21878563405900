/* eslint-disable operator-linebreak */
/* eslint-disable func-names */
/* eslint-disable-next-line operator-linebreak */
import axios from 'axios';
import { Message, Loading } from 'element-ui';
import _ from 'lodash';

import router from '@/router';
import store from '@/store';
import { getToken, removeToken } from '@/utils/auth';
import sysConfig from '@/config';
import tool from '@/utils/tool';
// loading对象
let loading;
// 当前正在请求的数量
let needLoadingRequestCount = 0;
// 显示loading
const showLoading = (target) => {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (needLoadingRequestCount === 0 && !loading) {
    loading = Loading.service({
      lock: true,
      text: 'Loading...',
      background: 'rgba(255, 255, 255, 0.5)',
      target: target || 'body'
    });
  }
  needLoadingRequestCount += 1;
};
// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
const toHideLoading = _.debounce(() => {
  loading.close();
  loading = null;
}, 800);

// 隐藏loading
function hideLoading() {
  needLoadingRequestCount -= 1;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 做个保护
  if (needLoadingRequestCount === 0) {
    // 关闭loading
    toHideLoading();
  }
}

const http = axios.create({
  baseURL: 'https://ow5api.test.xzusoft.cn/', // 'https://ow5api.pro.xzusoft.com/', //
  timeout: 10000
});
// 添加拦截器
http.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    let utoken = '';
    if (store.getters.token) {
      utoken = getToken();
    }
    if (config.headers.showLoading !== false) {
      showLoading();
    }
    console.log('data: ', config);
    // TODO 请求数据统一加密
    config.headers.AccessToken = utoken;

    config.headers.timestamp = Date.parse(new Date().toString()) / 1000;
    config.headers.appId = sysConfig.APP_ID;
    config.headers.Version = sysConfig.API_VERSION;

    const apiUrl = config.onUploadProgress ? config.onUploadProgress : '';
    if (apiUrl.indexOf('?') !== -1) {
      config.data = config.data || {};
      const query = apiUrl.split('?')[1].split('&');
      for (let i = 0; i < query.length; i + 1) {
        const key = query[i].split('=')[0];
        const value = query[i].split('=')[1];
        config.data[key] = value;
      }
    }
    config.headers.sign = tool.bulidSign(config.headers.timestamp, config.data);
    // TODO 上传无需格式化
    if (config.url.split('/').pop() === 'upload') {
      // 上传Content-Type设置
      config.headers['Content-Type'] = 'multipart/form-data;charse=UTF-8';
      return config;
    }
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';

    config.data = tool.crypto.AES.encrypt(
      JSON.stringify(config.data),
      sysConfig.APP_SECRET
    );
    return config;
  },
  (error) => {
    hideLoading();
    return Promise.reject(error);
  }
);
// http.defaults.transformRequest = [
//   // eslint-disable-next-line space-before-function-paren
//   function (data) {
//     console.log('dd', JSON.stringify(data));
//     // 对 data 进行任意转换处理
//     // return tool.crypto.AES.encrypt(JSON.stringify(data), sysConfig.APP_SECRET);
//   }
// ];
// 添加返回拦截器
http.interceptors.response.use(
  (response) => {
    // eslint-disable-nex/*  */t-line no-console
    hideLoading();
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据,否则的话抛出错误
    if (response.status === 200) {
      // 异步文件下载
      if (response.config.responseType === 'blob') {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'test.xlsx';
        a.click();
        return Promise.resolve(response.data);
      }
      const res = response.data;
      console.log('抛出接口异常:------', res);
      if (res.code !== 200) {
        Message({
          message: res.msg ? res.msg : '网络或系统服务错误!!!~',
          type: 'error',
          duration: 3 * 1000
        });
        console.error('抛出接口异常:------', response);
        return Promise.reject(new Error(response.data || '异常'));
      }
      console.log('接口成功:------', response.data);
      return Promise.resolve(response.data);
    }
    return Promise.reject(response);
  },
  (error) => {
    console.log(error);
    hideLoading();
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          removeToken();
          Message({
            message: '未登录',
            type: 'error',
            duration: 3 * 1000
          });
          setTimeout(() => {
            router.replace({
              path: '/login'
            });
          }, 1000);
          break;
        // 404请求不存在
        case 404:
          Message({
            message: error.response.data.msg
              ? error.response.data.msg
              : 'ERROR:未找到符合要求的资源或服务~',
            type: 'error',
            duration: 1500
          });
          break;
        case 502:
          Message({
            message: error.response.data.msg
              ? error.response.data.msg
              : 'ERROR:网络错误,~',
            type: 'error',
            duration: 1500
          });
          break;
        // 其他错误，直接抛出错误提示
        default:
          Message({
            message: error.response.data.msg || '网络或系统服务错误~',
            type: 'error',
            duration: 3 * 1000
          });
      }
      console.log('抛出接口异常------:', error);
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

export default http;
