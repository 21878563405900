import Layout from '@/layout/index';
// src/views/manage/product/refund.js
export default [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      },
      {
        path: '/data/panel',
        name: 'data-panel',
        component: () =>
          import(
            /* webpackChunkName: "resources" */ '@/views/system/data/panel.vue'
          ),
        meta: { title: '数据面板', affix: true }
      },
      {
        path: '/resources',
        name: 'resources',
        component: () =>
          import(
            /* webpackChunkName: "resources" */ '@/views/system/resources/index.vue'
          ),
        meta: { title: '资源管理', affix: true }
      },
      {
        path: '/sys/menu',
        name: 'sys-menu',
        component: () =>
          import(
            /* webpackChunkName: "sys-menu" */ '@/views/system/menu/index.vue'
          ),
        meta: { title: '菜单管理', affix: true }
      },
      {
        path: '/sys/accessory',
        name: 'sys-accessory',
        component: () =>
          import(
            /* webpackChunkName: "sys-role" */ '@/views/system/accessory/index.vue'
          )
      },
      {
        path: '/sys/role',
        name: 'sys-role',
        component: () =>
          import(
            /* webpackChunkName: "sys-role" */ '@/views/system/role/index.vue'
          ),
        meta: { title: '角色管理', affix: true }
      },
      {
        path: '/sys/pages',
        name: 'sys-pages',
        component: () =>
          import(
            /* webpackChunkName: "sys-role" */ '@/views/system/pages/index.vue'
          ),
        meta: { title: '协议须知', affix: true }
      },
      {
        path: '/manage/message/sms',
        name: 'sms',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/manage/message/sms.vue'
          )
      },
      {
        path: '/sys/account',
        name: 'sys-account',
        component: () =>
          import(
            /* webpackChunkName: "sys-account" */ '@/views/system/account/index.vue'
          ),
        meta: { title: '账号列表', affix: true }
      },
      {
        path: '/sys/setting',
        name: 'sys-setting',
        component: () =>
          import(
            /* webpackChunkName: "sys-setting" */ '@/views/system/setting/index.vue'
          ),
        meta: { title: '系统设置', affix: true }
      },
      {
        path: '/dict/type',
        name: 'dictionary',
        component: () =>
          import(
            /* webpackChunkName: "sys-dict-type" */ '@/views/system/dictionary/type/index.vue'
          ),
        meta: { title: '数据字典', affix: true }
      },
      {
        path: '/dict/data/:id',
        name: 'dictionaryData',
        component: () =>
          import(
            /* webpackChunkName: "sys-dict-data" */ '@/views/system/dictionary/data/index.vue'
          ),
        meta: { title: '数据字典', affix: true }
      },
      {
        path: '/banner/list',
        name: 'banner-list',
        component: () =>
          import(
            /* webpackChunkName: "banner-list" */ '@/views/manage/banner/index.vue'
          ),
        meta: { title: '轮播图管理', affix: true }
      },
      {
        path: '/article/list',
        name: 'article-list',
        component: () =>
          import(
            /* webpackChunkName: "article-list" */ '@/views/manage/article/index.vue'
          ),
        meta: { title: '文章管理', affix: true }
      },
      {
        path: '/member/list',
        name: 'member-list',
        component: () =>
          import(
            /* webpackChunkName: "member-list" */ '@/views/manage/member/index.vue'
          ),
        meta: { title: '会员管理', affix: true }
      },
      {
        path: '/report/total',
        name: 'report-total',
        component: () =>
          import(
            /* webpackChunkName: "report-total" */ '@/views/manage/report/total.vue'
          ),
        meta: { title: '数据统计', affix: true }
      },
      {
        path: '/member/board',
        name: 'member-board',
        component: () =>
          import(
            /* webpackChunkName: "member-board" */ '@/views/manage/board/index.vue'
          ),
        meta: { title: 'CEO信箱', affix: true }
      },
      {
        path: '/member/product',
        name: 'member-product',
        component: () =>
          import(
            /* webpackChunkName: "member-board" */ '@/views/manage/product/add.vue'
          ),
        meta: { title: '产品管理', affix: true }
      },
      {
        path: '/member/points',
        name: 'member-points',
        component: () =>
          import(
            /* webpackChunkName: "member-points" */ '@/views/manage/member/points/index.vue'
          ),
        meta: { title: '会员积分管理', affix: true }
      },
      {
        path: '/member/energy',
        name: 'member-energy',
        component: () =>
          import(
            /* webpackChunkName: "member-energy" */ '@/views/manage/member/energy/index.vue'
          ),
        meta: { title: '会员能量值日志', affix: true }
      },
      {
        path: '/member/recharge',
        name: 'member-recharge',
        component: () =>
          import(
            /* webpackChunkName: "member-energy" */ '@/views/manage/member/recharge/index.vue'
          ),
        meta: { title: '会员能量值充值日志', affix: true }
      },
      {
        path: '/member/signin',
        name: 'member-signin',
        component: () =>
          import(
            /* webpackChunkName: "member-signin" */ '@/views/manage/member/signin/index.vue'
          ),
        meta: { title: '会员签到日志', affix: true }
      },
      {
        path: '/member/group',
        name: 'member-group',
        component: () =>
          import(
            /* webpackChunkName: "member-list" */ '@/views/manage/member/group/index.vue'
          ),
        meta: { title: '会员分组', affix: true }
      },
      {
        path: '/member/rank',
        name: 'member-rank',
        component: () =>
          import(
            /* webpackChunkName: "member-list" */ '@/views/manage/member/rank/index.vue'
          ),
        meta: { title: '会员级别', affix: true }
      },
      {
        path: '/category/list',
        name: 'category-list',
        component: () =>
          import(
            /* webpackChunkName: "category-list" */ '@/views/manage/category/index.vue'
          ),
        meta: { title: '分类管理', affix: true }
      },
      {
        path: '/manage/teacher',
        name: 'manage-teacher',
        component: () =>
          import(
            /* webpackChunkName: "manage-teacher" */ '@/views/manage/teacher/index.vue'
          ),
        meta: { title: '商品标签', affix: true }
      },
      {
        path: '/manage/product',
        name: 'manage-product',
        component: () =>
          import(
            /* webpackChunkName: "manage-teacher" */ '@/views/manage/product/index.vue'
          ),
        meta: { title: '排课', affix: true }
      },
      {
        path: '/manage/coupon',
        name: 'manage-coupon',
        component: () =>
          import(
            /* webpackChunkName: "manage-teacher" */ '@/views/manage/coupon/index.vue'
          ),
        meta: { title: '优惠券管理', affix: true }
      },
      {
        path: '/manage/couponissuerule',
        name: 'manage-couponissuerule',
        component: () =>
          import(
            /* webpackChunkName: "manage-teacher" */ '@/views/manage/couponissuerule/index.vue'
          ),
        meta: { title: '优惠券发放规则', affix: true }
      },
      {
        path: '/product/tag',
        name: 'product-tag',
        component: () =>
          import(
            /* webpackChunkName: "product-tag" */ '@/views/manage/product/tag/index.vue'
          ),
        meta: { title: '商品标签', affix: true }
      },
      {
        path: '/product/category',
        name: 'product-category',
        component: () =>
          import(
            /* webpackChunkName: "product-category" */ '@/views/manage/product/category/index.vue'
          ),
        meta: { title: '商品分类', affix: true }
      },
      {
        path: '/product/attr',
        name: 'product-attr',
        component: () =>
          import(
            /* webpackChunkName: "product-attr" */ '@/views/manage/product/attr/index.vue'
          ),
        meta: { title: '销售类型', affix: true }
      },
      {
        path: '/product/attr/value/:id',
        name: 'product-attr-value',
        component: () =>
          import(
            /* webpackChunkName: "product-attr-value" */ '@/views/manage/product/attr/value.vue'
          ),
        meta: { title: '销售属性', affix: true }
      },
      {
        path: '/product/add',
        name: 'product-add',
        component: () =>
          import(
            /* webpackChunkName: "product-add" */ '@/views/manage/product/add.vue'
          ),
        meta: { title: '新增商品', affix: true }
      },
      {
        path: '/operating/have_class',
        name: 'have_class',
        component: () =>
          import(
            /* webpackChunkName: "have_class" */ '@/views/manage/operating/have_class.vue'
          ),
        meta: { title: '签到上课', affix: true }
      },
      {
        path: '/payment/list',
        name: 'payment',
        component: () =>
          import(
            /* webpackChunkName: "payment" */ '@/views/manage/payment/index.vue'
          ),
        meta: { title: '支付日志', affix: true }
      },
      {
        path: '/queue/list',
        name: 'queue',
        component: () =>
          import(
            /* webpackChunkName: "payment" */ '@/views/manage/queue/list.vue'
          ),
        meta: { title: '队列日志', affix: true }
      },
      {
        path: '/platformaction/list',
        name: 'platformaction',
        component: () =>
          import(
            /* webpackChunkName: "payment" */ '@/views/system/platformaction/list.vue'
          ),
        meta: { title: '平台交互日志', affix: true }
      },
      {
        path: '/member/weimobaccount',
        name: 'member-weimobaccount',
        component: () =>
          import(
            /* webpackChunkName: "payment" */ '@/views/manage/member/weimobaccount/index.vue'
          ),
        meta: { title: '微盟会员', affix: true }
      },
      {
        path: '/member/xiaobaoaccount',
        name: 'member-xiaobaoaccount',
        component: () =>
          import(
            /* webpackChunkName: "payment" */ '@/views/manage/member/xiaobaoaccount/index.vue'
          ),
        meta: { title: '校宝会员', affix: true }
      },
      // {
      //   path: '/orders/list',
      //   name: 'orders',
      //   component: () => import(
      //     /* webpackChunkName: "payment" */ '@/views/manage/orders/index.vue'
      //   ),
      //   meta: { title: '订单日志', affix: true }
      // },
      {
        path: '/trading/refund',
        name: 'trading-refund',
        component: () =>
          import(
            /* webpackChunkName: "trading-refund" */ '@/views/manage/trading/refund.vue'
          ),
        meta: { title: '退单申请', affix: true }
      },
      {
        path: '/manage/exchange',
        name: 'exchange',
        component: () =>
          import(
            /* webpackChunkName: "exchange" */ '@/views/manage/exchangeLog/index.vue'
          ),
        meta: { title: '兑换日志', affix: true }
      },
      {
        path: '/manage/workbench',
        name: 'workbench',
        component: () =>
          import(
            /* webpackChunkName: "workbench" */ '@/views/manage/workbench/index.vue'
          ),
        meta: { title: '工作台', affix: true }
      },
      {
        path: '/manage/grow',
        name: 'grow',
        component: () =>
          import(
            /* webpackChunkName: "workbench" */ '@/views/manage/grow/index.vue'
          ),
        meta: { title: '成长中心', affix: true }
      },
      {
        path: '/energy/list',
        name: 'energy-list',
        component: () =>
          import(
            /* webpackChunkName: "energy-list" */ '@/views/manage/energy/index.vue'
          ),
        meta: { title: '能量值充值模板列表', affix: true }
      },
      {
        path: '/manage/query',
        name: 'query-signin',
        component: () => import('@/views/manage/query/list.vue'),
        meta: { title: '成长中心', affix: true }
      },
      {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
      }
    ]
  }
];
