<template>
<div class="sub-menu">
  <SubNavList
    :typeIndex="typeIndex"
    :typeContextMenuList="typeContextMenuList"
    @onChange="typeOnChange"
    @editType="editType"
    @addType="addType"
    :typeCreate="typeCreate"
    :typeEdit="typeEdit"
    :typeList="typeList"
  ></SubNavList>
  <LoadingMini v-if="loading" />
  <SubNavType
    v-else
    :listIndex="listIndex"
    :create="create"
    :edit="edit"
    :list="list"
    :listName="listName"
    :listContextMenuList="listContextMenuList"
    @onChange="onChange"
    :listCreateButtonName="listCreateButtonName"
  ></SubNavType>
</div>
</template>

<script>
import LoadingMini from '@/components/Loading/mini';
import SubNavList from './list.vue';
import SubNavType from './type.vue';
import props from './props';

export default {
  name: 'Index',
  components: {
    SubNavList,
    SubNavType,
    LoadingMini
  },
  props,
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.sub-menu {
  height: 100%;
  background-color: #fff;
  width: 165px;
  display: flex;
  z-index: 100;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding-left: 27px;
  user-select: none;
}

</style>
