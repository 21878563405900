import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// global Mixins
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';

import '@/assets/styles/base.scss'; // global css

import axios from 'axios';
import AvueUeditor from 'avue-plugin-ueditor';
import http from './utils/http';
import xzWaves from './directive/waves'; // 水波纹
import Mixin from './mixins/common';
import store from './store';
import router from './router';
import App from './App';
import config from './config';

Vue.prototype.$config = config;

Vue.use(Avue, {
  axios,
  size: 'small',
  menuType: '',
  qiniu: {},
  ali: config.alioss,
  theme: '', // dark黑色主题
  canvas: {}
});

window.axios = http;
Vue.use(AvueUeditor);
Vue.use(ElementUI);
Vue.use(xzWaves);

Vue.directive('has', {
  inserted(el, binding) {
    if (Vue.prototype.$_has && !Vue.prototype.$_has(binding.value)) {
      el.parentNode.removeChild(el);
    }
  }
});

Vue.mixin(Mixin);
Vue.prototype.http = http;

Vue.config.productionTip = false;
Vue.prototype.$baseUrl = 'https://ow5api.test.xzusoft.cn/';// 'https://ow5api.pro.xzusoft.com/';   //
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
