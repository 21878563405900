<template>
  <div class="list">
    <div class="item" v-for="(item, index) in urlList" :key="index">
      <div @click="chenge(item)">
        <el-image
          style="width: 50px; height: 50px"
          :src="item.url"
          :fit="'cover'">
        </el-image>
      </div>
      <div
        class="icon"
        v-show="item[name] === chengeData"
        >
          <i :class="icon" :style="'color:' + iconColor || 'royalblue' + ';font-size:'+ iconSize +';'"></i>
        </div>
    </div>
  </div>
</template>

<script>
import './index.scss';

export default {
  name: 'Index',
  props: {
    urlList: {
      type: Array,
      default() {
        return [];
      }
    },
    iconColor: {
      type: String,
      default: 'royalblue'
    },
    data: {
      type: String,
      default: 'royalblue'
    },
    iconSize: {
      type: String,
      default: '20px'
    },
    name: {
      type: String,
      default: 'id'
    },
    icon: {
      type: String,
      default: 'el-icon-success'
    }
  },
  data() {
    return {
      chengeData: this.data
    };
  },
  methods: {
    chenge(item) {
      console.log(item);
      this.chengeData = item[this.name];
      this.$emit('chenge', item);
    }
  }
};
</script>

<style lang='scss' scoped></style>
