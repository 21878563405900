import { getSessionStore } from '@/utils/storage';

export default {
  BtnSize: 'mini', // 按钮大小
  menuType: {
    leftBGC: '#fff',
    backgroundColor: '#545c64',
    textColor: '#fff',
    activeCextColor: '#ffd04b'
  },
  table: {
    // 表格样式
    ...getSessionStore('TableConfig', 'JSONStr'),
    // ...tableDate
    searchMenuSpan: 4,

    labelWidth: 120,
    cancelBtn: true,
    selection: true
  },
  addBtnColor: '', // 添加按钮样式
  alioss: {
    // region: 'cn-zhangjiakou',
    // endpoint: 'i.tangchengshuke.com', // 'oss-accelerate.aliyuncs.com',
    // accessKeyId: 'LTAI5tECP64MemjHdubAiVCE',
    // accessKeySecret: '58kDJz9QJcD9swYV6USD1Gz1MsfeKy',
    // bucket: 'tcsk001',
    // cname: true
  },
  // 应用id
  APP_ID: '51586740',
  // 应用密钥
  APP_SECRET: 'dfgAiNQJdjnLimUzVXEtnDJrtFSkKCZx',
  // 应用版本
  API_VERSION: '20220126'
};
