<template>
  <div class="top-nav">
    <div class="top-nav__logo" v-if="logo" @click="test">
      <div class="top-nav__content">
        <span class="top-nav__content--title"> <i class="iconfont icon-liujisuan" />天枢 </span>
        <br />
        <span class="top-nav__content--desc">TIANSHU</span>
      </div>
    </div>

    <div class="top-nav__left">
      <TopMenu v-if="$store.getters.layoutType === 'layoutType1'" />
      <BreadCrumbs v-else />
    </div>
    <div class="top-nav__right">
         <el-avatar
v-if="$store.getters.userInfo.avatar && $store.getters.userInfo.avatar != ''"
          :size="32"
          :src="$store.getters.userInfo.avatar"></el-avatar>
          <el-avatar v-else :size="32" > {{$store.getters.userInfo.nick_name}} </el-avatar>
      <div style="margin-right: 10px;">
      </div>
      <el-dropdown trigger="click"  @command="handleCommand">
        <span class="el-dropdown-link">
          {{$store.getters.userInfo.nick_name}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item icon="el-icon-edit-outline" command="1">修改密码</el-dropdown-item>
          <el-dropdown-item icon="el-icon-switch-button" command="2" @click="logout">退出登陆</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="top-nav__item">
      </div>
    </div>
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawer"
      direction="rtl">
      <div class="drawer-content">
        <div>
          <SingleChoice v-show="true" @chenge="chenge" :data="$store.getters.layoutType" :urlList="urlList" :icon="'el-icon-success'" />
          <TableConfig />
        </div>
      </div>
    </el-drawer>
    <el-dialog
        title="修改密码"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="600px"
    >
      <avue-form ref="form" v-model="obj" :option="option" @reset-change="emptytChange" @submit="submit"></avue-form>
    </el-dialog>

  </div>
</template>

<script>
import './index.scss';
import * as user from '@/api/user.js';
import { removeToken, getToken, removeUserInfo } from '@/utils/auth';
import router from '@/router';
import TopMenu from './components/TopMenu';
import BreadCrumbs from './components/BreadCrumbs';
import SingleChoice from './components/SingleChoice';
import TableConfig from './components/TableConfig/index';

export default {
  name: 'Index',
  components: {
    TopMenu,
    BreadCrumbs,
    SingleChoice,
    TableConfig
  },
  props: {
    Type: {
      type: String,
      required: ''
    },
    logo: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      obj: {},
      dialogVisible: false,
      form: {
        card: false
      },
      urlList: [
        {
          id: 'layoutType1',
          url: '@/assets/img/layout/1.png'
        },
        {
          id: 'layoutType2',
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        },
        {
          id: 'layoutType3',
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        }
      ],
      // url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      drawer: false,
      layoutType: '',
      drawerTitle: '',
      option: {
        labelWidth: 130,
        column: [
          {
            label: '旧密码',
            span: 24,
            prop: 'oldcode',
            type: 'password',
            rules: [{
              required: true,
              message: '请输入旧密码',
              trigger: 'blur'
            }]
          },
          {
            label: '新密码',
            span: 24,
            prop: 'newcode',
            type: 'password',
            rules: [{
              required: true,
              message: '请输入新密码',
              trigger: 'blur'
            }]
          },
          {
            label: '确认新密码',
            span: 24,
            prop: 'password',
            type: 'password',
            rules: [{
              required: true,
              message: '请确认新密码',
              trigger: 'blur'
            }]
          }
        ]
      }
    };
  },
  created() {
    this.layoutType = this.$store.getters.layoutType;
  },
  watch: {
    layoutType(newV) {
      console.log(newV, this.layoutType);
      this.$store.dispatch('config/setLayoutType', newV);
    }
  },
  methods: {
    handleCommand(e) {
      switch (e) {
        case '1':
          this.dialogVisible = true;
          break;
        case '2':
          this.logout();
          break;
        default:
          break;
      }
    },
    // 关闭修改密码弹窗
    emptytChange() {
      this.dialogVisible = false;
    },
    submit(form, done) {
      user.changePassword(form).then(() => {
        this.dialogVisible = false;
        user.logout().then(() => {
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          removeToken();
          removeUserInfo();
          this.$router.push('/login');
        });
      });
      done();
      this.obj = {
        password: '',
        newPassword: '',
        oldPassword: ''
      };
    },
    test() {
      this.$router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
    },
    chenge(e) {
      this.$store.dispatch('config/setLayoutType', e.id);
    },
    logout() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        user.logout().then((res) => {
          console.log(res);
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          removeToken();
          removeUserInfo();
          console.log(getToken());
          // sessionStorage.clear();

          this.$router.push('/login');
        });
      });
      // await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
};
</script>

<style lang="scss">
.el-popover{
  padding: 0;
}
.popover-content{
    width: 100%;
    .content-item{
      &:hover{
        color: royalblue;
        background-color: #d4e9ff;
      }
      &.logout{
        border-top: 1px solid #dddd;
      }
      height: 30px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .drawer-content{
    width: 100%;
    padding: 10px;
  }
</style>
