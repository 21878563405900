import Vue from 'vue';
import Layout from '@/layout';
import $store from '@/store';
import {
  // _setSessionStore,
  getSessionStore
} from '@/utils/storage';

import VueRouter from 'vue-router';
// import fullpath from './modul/fullpath';
/* Layout */

Vue.use(VueRouter);
/**
 * 公共路由
 * 所有得角色权限都可以使用
 */
export const constantRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      },
      {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/common/login'),
    hidden: true
  },
  {
    path: '/forget',
    component: () => import('@/views/common/forget'),
    hidden: true
  },
  {
    path: '/register',
    component: () => import('@/views/common/register'),
    hidden: true
  },
  // {
  //   path: '/404',
  //   component: () => import('@/views/error-page/404'),
  //   hidden: true
  // },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  }
];
/**
 * asyncRoutes
 * 需要根据用户角色动态加载的路由
 */
export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
];
const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

function menuClass(to) {
  const navSelf = getSessionStore('navSelf', 'JSONStr');
  const menulist = getSessionStore('menulist', 'JSONStr');
  const menus = getSessionStore('menus', 'JSONStr');
  if (!navSelf) {
    $store.dispatch('config/setMenuType', {
      asideWidth: '50px',
      rightMenuType: false,
      ParentMenuUrl: 2019, // 一级菜单目录
      SubmenuUrl: '/', // 子菜单目录
      SubmenuTitle: '' // 子菜单title
    });
  } else if (menulist) {
    for (let index = 0; index < menulist.length; index++) {
      if (to.path === menulist[index].path) {
        navSelf.ParentMenuUrl = menulist[index].parent_id;
        menulist.forEach((item) => {
          if (item.id === menulist[index].parent_id && item.parent_id !== 0) {
            navSelf.ParentMenuUrl = item.parent_id;
            navSelf.rightMenuType = true;
          }
        });
        navSelf.SubmenuUrl = menulist[index].path;
        if (to.path === '/' || to.path === '/dashboard') {
          navSelf.ParentMenuUrl = menulist[index].id;
          navSelf.rightMenuType = false;
          navSelf.asideWidth = '50px';
        }
        menus.forEach((element) => {
          if (element.id === navSelf.ParentMenuUrl) {
            $store.dispatch('config/setSubmenuList', element.children);
          }
        });
        break;
      }
    }
    $store.dispatch('config/setMenuType', navSelf);
  }
}
router.beforeEach((to, from, next) => {
  menuClass(to);
  // if (to.path !== from.path) {
  // }
  if (to.name) {
    document.title = to.meta.title || to.name;
  }
  next();
});
export default router;
