/*
 * @Descripttion: 工具集
 * @version: 1.1
 * @LastEditors: sakuya
 * @LastEditTime: 2021年7月20日10:58:41
 */

import CryptoJS from 'crypto-js';
import sysConfig from '@/config';

const tool = {};

/* localStorage */
tool.data = {
  set(table, settings) {
    const jsonString = JSON.stringify(settings);
    return localStorage.setItem(table, jsonString);
  },
  get(table) {
    let data = localStorage.getItem(table);
    try {
      data = JSON.parse(data);
    } catch (err) {
      return null;
    }
    return data;
  },
  remove(table) {
    return localStorage.removeItem(table);
  },
  clear() {
    return localStorage.clear();
  }
};

/* sessionStorage */
tool.session = {
  set(table, settings) {
    const jsonString = JSON.stringify(settings);
    return sessionStorage.setItem(table, jsonString);
  },
  get(table) {
    let data = sessionStorage.getItem(table);
    try {
      data = JSON.parse(data);
    } catch (err) {
      return null;
    }
    return data;
  },
  remove(table) {
    return sessionStorage.removeItem(table);
  },
  clear() {
    return sessionStorage.clear();
  }
};

/* 复制对象 */
tool.objCopy = (obj) => JSON.parse(JSON.stringify(obj));

/* 日期格式化 */
tool.dateFormat = (date, fmt = 'yyyy-MM-dd hh:mm:ss') => {
  date = new Date(date);
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3) // 季度
    // 'S': date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
      );
    }
  }
  return fmt;
};

/* 千分符 */
tool.groupSeparator = (num) => {
  num += '';
  if (!num.includes('.')) {
    num += '.';
  }
  return num
    .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => `${$1},`)
    .replace(/\.$/, '');
};

/* 常用加解密 */
tool.crypto = {
  // MD5加密
  MD5(data) {
    return CryptoJS.MD5(data).toString();
  },
  // BASE64加解密
  BASE64: {
    encrypt(data) {
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
    },
    decrypt(cipher) {
      return CryptoJS.enc.Base64.parse(cipher).toString(CryptoJS.enc.Utf8);
    }
  },
  // AES加解密
  AES: {
    encrypt(data, secretKey) {
      const keyHex = CryptoJS.enc.Utf8.parse(secretKey);
      const result = CryptoJS.DES.encrypt(data, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return result.ciphertext.toString(CryptoJS.enc.Base64);
    },
    decrypt(cipher, secretKey) {
      const result = CryptoJS.DES.decrypt(
        cipher,
        CryptoJS.enc.Utf8.parse(secretKey),
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }
      );
      return CryptoJS.enc.Utf8.stringify(result);
    }
  }
};
/** 传入一个对象和布尔值，return根据ASCII码升序或者降序的对象
 * @param {Object} obj
 * @param {Boolean} isAsc true代表升序，false代表降序
 */
tool.objSortASCII = (obj, isASC) => {
  const arr = [];
  Object.keys(obj).forEach((item) => arr.push(item));

  const sortArr = isASC ? arr.sort() : arr.sort().reverse();
  const sortObj = {};
  for (const i in sortArr) {
    sortObj[sortArr[i]] = obj[sortArr[i]];
  }
  return sortObj;
};
/**
 * 删除数组中或对象中的空值
 * @param {*} obj
 * @returns
 */
tool.paraFilter = (obj) => {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] === '' || obj[i] === null || obj[i] === undefined) {
        obj.splice(i, 1);
        i--;
      }
    }
  } else {
    for (const key in obj) {
      if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  return obj;
};
/**
 * 签名
 * @param token 身份令牌
 * @param timestamp 签名时间戳
 * @param data 签名数据
 */
tool.bulidSign = (timestamp, data) => {
  // 签名格式： timestamp + app_secret + data(字典升序)
  const ret = [];
  let body = '';
  if (typeof data === 'string') {
    body = data;
  } else if (typeof data === 'object') {
    data = tool.paraFilter(data);
    data = tool.objSortASCII(data, true);
    for (const it in data) {
      if (typeof data[it] !== 'object' && it !== 'sign') {
        ret.push(`${it}=${data[it]}`);
      } else {
        console.log('JSON data', data[it]);
        ret.push(`${it}=${JSON.stringify(data[it])}`);
      }
    }
    body = ret.join('&');
  }
  const signsrc = `app_secret=${sysConfig.APP_SECRET}&${body}&timestamp=${timestamp}`;
  console.log('signsrc', signsrc);
  // MD5加密并转大写
  return tool.crypto.MD5(signsrc).toUpperCase();
};
/**
 * 路由扁平化
 * @param {*} routes
 * @param {*} breadcrumb
 * @returns
 */
tool.flatAsyncRoutes = (routes, breadcrumb = []) => {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    // console.log('tmp', tmp);
    if (tmp.children) {
      const childrenBreadcrumb = [...breadcrumb];
      childrenBreadcrumb.push(route);
      const tmpRoute = { ...route };
      // tmpRoute.meta.breadcrumb = childrenBreadcrumb;
      delete tmpRoute.children;
      res.push(tmpRoute);
      const childrenRoutes = tool.flatAsyncRoutes(
        tmp.children,
        childrenBreadcrumb
      );
      res.push(...childrenRoutes);
      // childrenRoutes.map((item) => {
      //   res.push(item);
      // });
    } else {
      const tmpBreadcrumb = [...breadcrumb];
      tmpBreadcrumb.push(tmp);
      // tmp.meta.breadcrumb = tmpBreadcrumb;
      res.push(tmp);
    }
  });
  // console.log('log', res);
  return res;
};
export default tool;
