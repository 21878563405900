<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Vue from 'vue';
import { getToken } from '@/utils/auth';
import tool from '@/utils/tool.js';
import * as user from '@/api/user';
import { getSessionStore, setSessionStore } from '@/utils/storage';
import lodash from 'lodash';
import constantRoutes from './router/modul/fullpath';
import * as util from './assets/util.js';

export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      // menuData: null,
      isRouterAlive: true
    };
  },
  created() {
    // permissions.signin(this);
    const test = {
      size: 'small', // medium / small / mini
      searchMenuSpan: 6,
      card: true, // 卡片
      border: true, // 边框
      filterBtn: true, // 筛选
      tip: false, // 多选表格上方显示的tag
      labelWidth: 120,
      cancelBtn: true,
      selection: true,
      index: true,
      dialogClickModal: false,
      searchIndex: 1,
      searchSize: 'small'
    };
    const temp = getSessionStore('TableConfig', 'JSONStr') || test;
    this.$store.dispatch('config/setTableConfig', temp);
    this.signin();
  },
  methods: {
    /* eslint-disable no-useless-escape */
    /* eslint-disable array-callback-return */
    // 每次申请接口查看是否拥有权限
    setInterceptor(resourcePermission) {
      instance.interceptors.request.use((config) => {
        // Get request path
        let perName = config.url.replace(config.baseURL, '').split('?')[0];
        // RESTful type 1 /path/**
        const reg1 = perName.match(/^(\/[^\/]+\/)[^\/]+$/);
        if (reg1) {
          perName = `${reg1[1]}**`;
        }
        // RESTful type 2 /path/*/path
        const reg2 = perName.match(/^\/[^\/]+\/([^\/]+)\/[^\/]+$/);
        if (reg2) {
          perName = perName.replace(reg2[1], '*');
        }
        // Check permissions
        // 查看当前所申请的接口 是否有申请权限
        if (!resourcePermission[`${config.method},${perName}`]) {
          this.$message({
            message: '无访问权限，请联系企业管理员',
            type: 'warning'
          });
          return Promise.reject({
            message: 'no permission'
          });
        }
        return config;
      });
    },
    getResources(userPermissions) {
      const resourceHash = {};
      if (Array.isArray(userPermissions.resources)) {
        /*
        * Input like this:
        * [{
        *   id: "2c9180895e172348015e1740805d000d"
            method: "GET"
            url: "/some-url"
        * }]
        */
        userPermissions.resources.forEach((e) => {
          const key = `${e.method.toLowerCase()},${e.url}`;
          resourceHash[key] = true;
        });
      }
      // Get hash structure
      return resourceHash;
    },
    getRoutes(userPermissions) {
      const routeHash = {};
      const setMenu2Hash = (array, base) => {
        array.map((key) => {
          if (key.path) {
            const hashKey = ((base ? `${base}/` : '') + key.path).replace(
              /^\//,
              ''
            );
            routeHash[`/${hashKey}`] = true;
            if (Array.isArray(key.children)) {
              setMenu2Hash(key.children, (base ? `${base}/` : '') + key.path);
            }
          }
        });
      };
      if (Array.isArray(userPermissions.menus)) {
        /*
         * Input Like this:
         * [{
         *   id: "2c9180895e13261e015e13469b7e0000",
         *   name: "账户管理",
         *   parent_id: "2c9180895e13261e015e13469b7e0000",
         *   route: "some-route"
         * }]
         */
        // const arrayMenus = util.buildMenu(userPermissions.menus);
        const arrayMenus = userPermissions.menus;
        setMenu2Hash(arrayMenus);
      }
      // Get hash structure
      return routeHash;
    },
    // 过滤本地路由 获取实际路由
    extendRoutes(routePermission) {
      // Filtering local routes, get actual routing
      let actualRouter = [];
      const findLocalRoute = (array, base) => {
        const replyResult = [];
        array.forEach((route) => {
          const pathKey = (base ? `${base}/` : '') + route.path;
          if (routePermission[pathKey]) {
            if (Array.isArray(route.children)) {
              route.children = findLocalRoute(
                route.children,
                (base ? `${base}/` : '') + route.path
              );
            }
            replyResult.push(route);
          }
        });
        if (base) {
          return replyResult;
        }
        actualRouter = actualRouter.concat(replyResult);
      };
      findLocalRoute(constantRoutes[0].children);
      // If the user does not have any routing authority

      if (!actualRouter || !actualRouter.length) {
        // clear token, refresh page will jump to login screen.
        util.session('token', '');
        // Interface hints
        // eslint-disable-next-line operator-linebreak
        return (document.body.innerHTML =
          '<h1>账号访问受限，请联系系统管理员！</h1>');
      }
      actualRouter = actualRouter.map((e) => {
        // Copy 'children' to 'meta' for rendering menu.(This step is optional.)
        if (e.children) {
          if (!e.meta) e.meta = {};
          e.meta.children = e.children;
        }

        return e;
      });

      // Add actual routing to application
      const originPath = util.deepcopy(constantRoutes);
      originPath[0].children = actualRouter;
      // originPath[0].children = [];
      // this.$router.options.routes[0].children = actualRouter;
      // console.log('--------------------1路由过滤', constantRoutes);
      // console.log('--------------------2路由过滤', actualRouter);
      // console.log('--------------------3路由过滤', originPath);
      // console.log('--------------------3路由过滤', this.$router);
      // console.log('--------------------4路由过滤', this.$router);
      this.$router.addRoutes(
        originPath.concat([
          {
            path: '*',
            redirect: '/404'
          }
        ])
      );
      // Save information for rendering menu.(This step is optional.)
      this.$root.menuData = originPath[0].children;
    },
    signin() {
      // console.log('+++++++++++++++++++1++++++++++++++++', $router);
      /*
       * Step 1
       * Check whether the user has access
       */
      // 使用token换取 菜单 以及 接口权限
      const localUser = getToken();
      // console.log('++++++++++++++++++++1+++++++++++++++', localUser);
      if (!localUser || localUser === 'undefined') {
        return this.$router.push({
          path: '/login'
        });
      }
      /*
       * Step 2
       * Set Authorization
       */

      // instance.defaults.headers.common.Authorization = `Bearer ${localUser.token}`;

      /*
       * Step 2-1(This step is optional.)
       * Get user`s permissions
       * You can also get permission information upon user login, it depends on the implementation of the backend interface
       */
      // user.getAuthList({ token: localUser }).then((res) => {
      // });

      user.getAuthList({ token: localUser }).then((res) => {
        console.log('+++++++++++++++++++++', res.data.menu);
        user.getInfo(getToken()).then((res) => {
          this.$store.dispatch('user/setUserInfo', res.data);
        });
        const resMenu = lodash.cloneDeep(res.data.menu);
        this.$store.dispatch('config/setmenuList', res.data.menu);
        setSessionStore('menus', res.data.menu, 'JSONStr');
        const menuList = tool.flatAsyncRoutes(resMenu);

        const userPermissions = {
          resources: res.data.resource,
          menus: menuList
        };
        // console.log(userPermissions);
        setSessionStore('menulist', menuList, 'JSONStr');

        // Save information, if it is used elsewhere.
        // $root.userData = userPermissions;

        /*
        资源权限
         * Step 3
         * Get resourcePermission form user permissions
         * Like this:
         * { "get,/url1": true, "post,/url2": true, ... }
         */
        // const resourcePermission = this.getResources(userPermissions);
        // setSessionStore('resourcePermission', resourcePermission, 'JSONStr');

        /*
         * Step 4
         * Get routePermission form user permissions
         * Like this:
         * { "/route1": true, "/route2": true, ... }
         */
        const routePermission = this.getRoutes(userPermissions);
        setSessionStore('routePermission', routePermission, 'JSONStr');

        /*
         * Step 5
         * Setting request permission control through resourcePermission
         */
        //  通过resourcePermission设置请求权限控制
        // console.log('Step 5');
        // console.log('运行 setInterceptor 方法');
        // console.log('setInterceptor 方法传入参数');
        // console.log(resourcePermission);
        // setInterceptor(resourcePermission);

        /*
         * Step 6
         * Adding routing privileges to users
         */
        //  添加路由权限
        // 过滤本地路由 获取实际路由
        this.extendRoutes(routePermission);

        // /*
        //  * Step 7
        //  * Implementing $_has function, support for the directive "has" (in /main.js)
        //  * Input: Array, like this: ['get,/some-uri']
        //  * Output: Boolean
        //  */
        Vue.prototype.$_has = (rArray) => {
          console.log(rArray, '$_has');
          // const RequiredPermissions = rArray;
          const permission = true;
          // if (Array.isArray(rArray)) {
          //   rArray.forEach((e) => {
          //     if (e && e.p) {
          //       RequiredPermissions = RequiredPermissions.concat(e.p);
          //     }
          //   });
          // } else if (rArray && rArray.p) {
          //   RequiredPermissions = rArray.p;
          // }
          // for (let i = 0; i < RequiredPermissions.length; i++) {
          //   const p = RequiredPermissions[i];
          //   if (!resourcePermission[p]) {
          //     permission = false;
          //     break;
          //   }
          // }
          // console.log('return permission', permission);
          // console.log('$_has 结束----------------------');
          return permission;
        };
        // typeof callback === 'function' && callback();
      });
    }
  }
};
</script>
<style lang="scss">
@import '~@/assets/styles/icon.css';
@import 'assets/animate.min.css';
</style>
