import http from '@/utils/http';

export function login(data) {
  return http({
    url: '/user/login',
    method: 'post',
    data
  });
}

export function getInfo() {
  return http({
    url: '/user/info',
    method: 'post'
    // params: { token }
  });
}

export function getAuthList(data) {
  return http({
    url: '/system/user/auth_list',
    method: 'post',
    data
  });
}

export function logout() {
  return http({
    url: '/user/logout',
    method: 'post'
  });
}

export function changePassword(data) {
  return http({
    url: '/system/user/change_password',
    method: 'post',
    data
  });
}
